import PropTypes from 'prop-types';
import React from 'react';

import { getSeoConfig } from '../../../../constants/defaultSeoProps';
import ComplianceServiceHeroImage from '../../../../assets/services/ComplianceServiceHeroImage.png';
import Layout from '../../../../components/Layout';
import PageHeader from '../../../../components/PageHeader';
import ServiceSection from '../../../../components/ServiceSection';
import s from './Compliance.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  approachDetails: PropTypes.shape({}).isRequired,
  supportedFrameworksDetails: PropTypes.shape({}).isRequired,
  vantaDetails: PropTypes.shape({}).isRequired,
  implementationProcessDetails: PropTypes.shape({}).isRequired,
  technicalIntegrationAreaDetails: PropTypes.shape({}).isRequired,
  approachBenefitsDetails: PropTypes.shape({}).isRequired,
  gettingStartedDetails: PropTypes.shape({}).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/compliance.png',
  ogTitle: 'Security Compliance Services | Tech Holding',
  ogDesc:
    'Streamline your journey to SOC 2, ISO 27001, and CIS compliance. Automated compliance monitoring and evidence collection powered by Vanta.',
  ogPath: '/services/security/compliance',
};

const updatedSeo = getSeoConfig(seoParams);

const Security = (props) => {
  const {
    title,
    description,
    approachDetails,
    supportedFrameworksDetails,
    vantaDetails,
    implementationProcessDetails,
    technicalIntegrationAreaDetails,
    approachBenefitsDetails,
    gettingStartedDetails,
    companyQuote,
  } = props;

  return (
    <Layout
      currentPage="/services/security/compliance"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      {/* Header */}
      <PageHeader title={title} description={description} />

      <div className={s.pageContainer}>
        {/* Hero Image */}
        <img src={ComplianceServiceHeroImage} alt={title} />

        {/* Our Compliance Approach */}
        <div className={s.approachSection}>
          <ServiceSection {...approachDetails} />
        </div>

        {/* Supported Frameworks */}
        <ServiceSection {...supportedFrameworksDetails} />

        {/* About Vanta Integration */}
        <ServiceSection {...vantaDetails} />

        {/* Implementation Process */}
        <ServiceSection {...implementationProcessDetails} />

        {/* Technical Integration Area */}
        <ServiceSection {...technicalIntegrationAreaDetails} />

        {/* Approach Benefits */}
        <ServiceSection {...approachBenefitsDetails} />

        {/* Getting Started */}
        <ServiceSection {...gettingStartedDetails} />
      </div>
    </Layout>
  );
};

Security.propTypes = propTypes;
export default Security;
