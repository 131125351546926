import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Compliance from '../../../containers/Services/Security/Compliance';

const title = 'Security Compliance Services';
const description =
  "Building trust with customers and partners requires demonstrating your commitment to security through recognized compliance frameworks. We simplify your journey to compliance by combining our technical expertise with Vanta's automated compliance platform, helping you achieve and maintain certifications efficiently.";
const approachDetails = {
  title: 'Our Compliance Approach',
  description:
    'Rather than treating compliance as a checkbox exercise, we integrate it into your engineering and operational workflows. By leveraging automation and continuous monitoring, we help you build a sustainable compliance program that scales with your business while minimizing operational overhead.',
};
const supportedFrameworksDetails = {
  title: 'Supported Frameworks',
  topics: [
    {
      title: 'SOC 2',
      description:
        'SOC 2 defines criteria for managing customer data based on five trust service principles: security, availability, processing integrity, confidentiality, and privacy.',
      startingText: 'We guide you through:',
      points: [
        'Scoping and readiness assessment',
        'Control implementation and documentation',
        'Technical infrastructure alignment',
        'Evidence collection automation',
        'Audit preparation and support',
        'Continuous compliance monitoring',
      ],
    },
    {
      title: 'ISO 27001',
      description:
        'ISO 27001 provides a framework for establishing, implementing, maintaining, and continually improving an information security management system (ISMS).',
      startingText: 'Our services include:',
      points: [
        'Gap analysis and risk assessment',
        'ISMS design and implementation',
        'Control selection and implementation',
        'Internal audit support',
        'Certification audit preparation',
        'Ongoing ISMS maintenance',
      ],
    },
    {
      title: 'CIS Benchmarks',
      description:
        'CIS Benchmarks provide consensus-driven security configuration guidelines for systems and services.',
      startingText: 'We help you:',
      points: [
        'Assess current configurations against CIS Benchmarks',
        'Implement automated configuration management',
        'Monitor configuration drift',
        'Generate compliance reports',
        'Remediate deviations',
        'Maintain continuous compliance',
      ],
    },
  ],
};
const vantaDetails = {
  title: 'Vanta Integration',
  description:
    'As a Vanta partner, we leverage their industry-leading compliance automation platform to streamline your certification process:',
  topics: [
    {
      title: 'Automated Evidence Collection',
      points: [
        'Continuous monitoring of your cloud infrastructure',
        'Automatic evidence gathering from integrated systems',
        'Real-time compliance status tracking',
        'Automated testing of security controls',
      ],
    },
    {
      title: 'Streamlined Workflows',
      points: [
        'Centralized policy management',
        'Automated task assignment and tracking',
        'Integrated evidence repository',
        'Simplified audit preparation',
      ],
    },
    {
      title: 'Comprehensive Monitoring',
      points: [
        'Real-time compliance dashboards',
        'Automated risk assessments',
        'Configuration monitoring',
        'Employee security tracking',
      ],
    },
  ],
};
const implementationProcessDetails = {
  title: 'Our Implementation Process',
  topics: [
    {
      title: '1. Assessment Phase',
      points: [
        'Review current security posture',
        'Identify applicable compliance requirements',
        'Gap analysis against chosen frameworks',
        'Roadmap development',
      ],
    },
    {
      title: '2. Implementation Phase',
      points: [
        'Control implementation',
        'Policy development',
        'Technical configuration',
        'Vanta platform setup',
        'Integration with existing tools',
      ],
    },
    {
      title: '3. Validation Phase',
      points: [
        'Internal compliance testing',
        'Documentation review',
        'Evidence collection verification',
        'Mock audit execution',
      ],
    },
    {
      title: '4. Certification Phase',
      points: [
        'Auditor selection and engagement',
        'Evidence package preparation',
        'Audit coordination',
        'Remediation support',
      ],
    },
    {
      title: '5. Maintenance Phase',
      points: [
        'Continuous monitoring',
        'Regular control testing',
        'Policy updates',
        'Ongoing evidence collection',
        'Periodic assessments',
      ],
    },
  ],
};
const technicalIntegrationAreaDetails = {
  title: 'Technical Integration Areas',
  topics: [
    {
      title: 'We help implement and configure security controls across your infrastructure:',
      points: [
        'Cloud Platforms (AWS, Azure, GCP)',
        'Identity and Access Management',
        'Device Management',
        'Network Security',
        'Data Protection',
        'Development Workflows',
        'Third-party Risk Management',
      ],
    },
  ],
};
const approachBenefitsDetails = {
  title: 'Benefits of Our Approach',
  topics: [
    {
      title: 'Efficiency Through Automation',
      points: [
        'Reduced manual evidence collection',
        'Automated control testing',
        'Streamlined documentation',
        'Continuous compliance monitoring',
      ],
    },
    {
      title: 'Technical Expertise',
      points: [
        'Deep understanding of control implementation',
        'Infrastructure security best practices',
        'Cloud-native security controls',
        'DevSecOps integration',
      ],
    },
    {
      title: 'Sustainable Compliance',
      points: [
        'Built-in continuous monitoring',
        'Automated evidence refresh',
        'Regular control validation',
        'Integrated with development workflows',
      ],
    },
    {
      title: 'Cost-Effective',
      points: [
        'Reduced audit preparation time',
        'Minimized operational overhead',
        'Automated evidence collection',
        'Efficient control implementation',
      ],
    },
  ],
};
const gettingStartedDetails = {
  title: 'Getting Started',
  description:
    'Our team can help you determine the most appropriate compliance framework for your business and create a roadmap to certification. Contact us to discuss your compliance goals and how we can help you achieve them efficiently.',
};

const details = {
  title,
  description,
  approachDetails,
  supportedFrameworksDetails,
  vantaDetails,
  implementationProcessDetails,
  technicalIntegrationAreaDetails,
  approachBenefitsDetails,
  gettingStartedDetails,
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

const CompliancePage = ({ data }) => (
  <Compliance {...details} companyQuote={data.contentfulCompanyQuote} />
);

CompliancePage.propTypes = propTypes;

export default CompliancePage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
